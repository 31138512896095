.site-name {
  margin-bottom: 0;

  .logo {
    img {
      max-width: 25.5rem;
    }
  }
}

