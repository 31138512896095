//
// THROBBER
// ---------------------------------------------------------------------
// This file contains styling for the throbber.
//

html.js input.form-autocomplete,
html.js input.throbbing {
  background: 0 none;
}

// these apply to all ajax progresses
.ajax-progress {
  display: inline-block;
}
.ajax-progress .throbber {
  display: inline;
  margin: 0;
  margin-top: -3px;
  padding: 0;
  height: 15px;
  width: 15px;
  background: transparent url('../images/throbber.gif') no-repeat 0px -20px;
}
.pager .throbber {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.ajax-progress-throbber .message {
  display: none;
}
