//
// PAGER
// ---------------------------------------------------------------------
// This file contains styling for pagers.
//

ul.pager {
  margin-top: 2.75rem;
  text-align: center;
  clear: both;

  li {
    @include reset();
    display: inline-block;

    a {
      display: block;
      width: 32px;
      height: 32px;
      line-height: 34px;
      vertical-align: baseline;
      text-decoration: none;

    }

    &.pager-item a{
      &:hover {
        background-color: $color-primary;
        border-radius: 50%;
        color: $color-white;
        font-weight: normal;
        width: 32px;
        height: 32px;
        line-height: 34px;
        vertical-align: baseline
      }
    }

    &.pager-first a {
      @include icon('arrow-first', $replace: true);
    }

    &.pager-previous a {
      @include icon('arrow-left', $replace: true);
    }

    &.pager-current {
      background-color: $color-primary;
      border-radius: 50%;
      color: $color-white;
      font-weight: normal;
      width: 32px;
      height: 32px;
      line-height: 34px;
      vertical-align: baseline;
    }

    &.pager-next a {
      @include icon('arrow-right', $replace: true);
    }

    &.pager-last a {
      @include icon('arrow-last', $replace: true);
    }
  }
}
