//
// TABLES
// ---------------------------------------------------------------------
// This file contains styling for tables.
//


table, tbody, thead  {
  border: none;
}
table {
  border-collapse: collapse;
  border-right: 1px solid $color-border;
  border-left: 1px solid $color-border;

  margin: 0 0 1rem 0;
  width: 100%;
  overflow-x: auto;
  display: block;
}

thead {
  background-color: #ccc;
}

table td, table th {
  padding: 0.5rem;
  vertical-align: top;
  border: 1px solid $color-border;
}

tr.even, tr.odd {
  border-color: $color-border;
  border-style: solid;
  border-width: 0 1px;
}
tr.even {
  background-color: lighten($color-primary, 45);
}
tr.odd {
  background-color: $color-white;
}

