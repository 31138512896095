//
// TABS
// ---------------------------------------------------------------------
// This file contains styling for (page) tabs.
//

div.tabs {
  margin-bottom: 1rem;

  ul.tabs,
  ul.tabs li {
    @include reset-list;
  }

  ul.tabs {
    border: none;

    li {
      display: block;

      @include sm() {
        display: inline-block;
      }

      a {
        background-color: $color-grey;
        border: none;
        display: block;
        padding: 0.625rem 0.75rem;
        color: $color-white;

        &:hover, &:focus {
          background-color: darken($color-primary, 5%);

        }

        &.active {
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }
  }
}
