//
// BLOCKS
// ---------------------------------------------------------------------
// This file contains styling for blocks.
//

.block {
  //background: rgba($color-white, 0.5);
  //border: $default-border;
  margin-bottom: 1rem;
  .block__title {
    padding: 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .block__content {
    padding: 1rem;
  }
}
