//
// CLASSES
// ---------------------------------------------------------------------
// Defines global modifier classes to be assigned to blocks, panes, ...
//



//CUSTOM
.clearfix {
  @include clearfix;
}

.visible {
  visibility: visible;
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all $default-transition;
  transform: $transform-reset;
}

.invisible {
  visibility: hidden;
  transform: $transform-left-right;

}

.button-read-more {
  @extend .btn;
  @extend .btn-small;
  @extend .btn-primary;
  position: relative;


  @include icon('arrow-right', after) {
    margin-left: 0.3rem;
    transition: font-weight $default-transition;
    font-size: 1rem;

  }
  &:hover:after {
    font-weight: bold;
  }
}

.small-container {
  padding: 1rem 0;

  @include sm {
    padding: 1.5rem;
  }

  @include md {
    padding: 3rem;
    width: span(10 of 12);
  }

  @include lg {
    width: span(9 of 12);
  }
}
.small-container-sidebar {
  padding: 1rem 0;

  @include sm {
    padding: 1.5rem;
  }

  @include md {
    padding: 3rem;
    width: span(10 of 12);
  }

  @include lg {
    width: span(5 of 12 last);
  }
}
//
//EFFECTS
//
.list-effect {
  a {
    font-family: $default-font;
    font-weight: bold;
    color: $default-text-color;
    @include font-size(14);
    word-wrap: break-word;
    display: block;
    background-color: $color-white;
    padding: 0.5rem 0;
    z-index: 1;
    outline: 0;

    &:before, &:after {
      content: "";
      width: 2rem;
      left: -1rem;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: -1;
      filter: alpha(opacity=0);
      opacity: 0;
      //transition: opacity $slow-transition-time, transform $slow-transition-time;
    }

    &:before {
      border-left: 3px solid $color-primary;
      //transform: $scale-width;

    }

    &:after {
      border-top: 3px solid $color-primary;
      border-bottom: 3px solid $color-primary;
      //transform: $scale-width;
    }

    &:hover {
      color: $color-dark;
      text-decoration: none;

      &:before, &:after {
        filter: alpha(opacity=100);
        opacity: 1;
        //transform: scale(1);
      }
    }
  }
}

.slide-right-left {
  //NEEDS HOVER EFFECT
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: $color-primary;
    content: '';
  }
}

// Mobile

.hide-mobile {
  display: none;
  @include sm { display: block; }
}
.show-mobile {
  @include sm { display: none; }
}

// Floats

.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Extends

.extendfull {
  @include bleed-x(3000px);
}
.extendleft {
  padding-left: 3000px !important;
  margin-left: -3000px !important;
}
.extendright {
  padding-right: 3000px !important;
  margin-right: -3000px !important;
}

// Colors

.color-primary {
  color: $color-primary;
}
.color-secondary {
  color: $color-secondary;
}
.color-grey {
  color: $color-grey;
}
.color-white {
  color: $color-white;
}

// Background colors

.background-primary {
  background: $color-primary;
}
.background-secondary {
  background: $color-secondary;
}
.background-grey {
  background: $color-grey;
}
.background-white {
  background: $color-white;
}

// Border

.default-border {
  border: $default-border;
}
.default-border-top {
  border-top: $default-border;
}
.default-border-right {
  border-right: $default-border;
}
.default-border-bottom {
  border-bottom: $default-border;
}
.default-border-left {
  border-left: $default-border;
}

// Margin

.no-margin {
  margin: 0;
}
.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0;
}
.no-margin-horizontal {
  margin-left: 0;
  margin-right: 0;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-right {
  margin-right: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-margin-left {
  margin-left: 0;
}

// Padding

.no-padding {
  padding: 0;
}
.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-padding-left {
  padding-left: 0;
}

// Fonts

.font-light {
  @include font(200);
}
.font-regular {
  @include font();
}
.font-medium {
  @include font(500);
}
.font-bold {
  @include font(700);
}

// Drupal

.element-hidden {
  display: none;
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  &.element-focusable:active,
  &.element-focusable:focus {
    position: static !important;
    clip: auto;
    overflow: visible;
    height: auto;
  }
}
