// Container Syntax
// ================

// Container [mixin]
// -----------------
// Set a container element
// - [$layout]  : <settings>
@mixin container(
  $layout: $susy
) {
  $inspect    : $layout;
  $layout     : parse-grid($layout);

  $_width     : get-container-width($layout);
  $_justify   : parse-container-position(susy-get(container-position, $layout));
  $_property  : if(susy-get(math, $layout) == static, width, max-width);

  $_box       : susy-get(box-sizing, $layout);

  @if $_box {
    @include output((box-sizing: $_box));
  }

  @include susy-inspect(container, $inspect);
  @include float-container($_width, $_justify, $_property);
  @include show-grid($layout);
}

// Container [function]
// --------------------
// Return container width
// - [$layout]  : <settings>
@function container(
  $layout: $susy
) {
  $layout: parse-grid($layout);
  @return get-container-width($layout);
}

// Get Container Width
// -------------------
// Calculate the container width
// - [$layout]: <settings>
@function get-container-width(
  $layout: $susy
) {
  $layout         : parse-grid($layout);
  $_width         : susy-get(container, $layout);
  $_column-width  : susy-get(column-width, $layout);
  $_math          : susy-get(math, $layout);

  @if not($_width) or $_width == auto {
    @if valid-column-math($_math, $_column-width) {
      $_columns   : susy-get(columns, $layout);
      $_gutters   : susy-get(gutters, $layout);
      $_spread    : if(is-split($layout), wide, narrow);
      $_width     : susy-sum($_columns, $_gutters, $_spread) * $_column-width;
    } @else {
      $_width: 100%;
    }
  }

  @return $_width;
}

// Parse Container Position
// ------------------------
// Parse the $container-position into margin values.
// - [$justify]   : left | center | right | <length> [<length>]
@function parse-container-position(
  $justify: map-get($susy-defaults, container-position)
) {
  $_return: if($justify == left, 0, auto) if($justify == right, 0, auto);

  @if not(index(left right center, $justify)) {
    $_return: nth($justify, 1);
    $_return: $_return if(length($justify) > 1, nth($justify, 2), $_return);
  }

  @return $_return;
}
