//
// BUTTONS
// ---------------------------------------------------------------------
// This file contains all button styling.
//

.btn {
  display: inline-block;
  border: none;
  cursor: pointer;
  text-decoration: none !important;
  font-family: $bold-font;
  @include appearance(none);
  & + .btn {
    margin-left: 0.5rem;
  }
  // Sizes
  &-small {
    padding: 0.5rem 0.8rem;
    @include font-size(14, 14);
  }
  &-medium {
    font-weight: 400;
    padding: 0.5rem 1rem;
    @include font-size(16, 24);
  }
  &-large {
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    @include font-size(20, 24);
  }
  // Colors
  @each $type, $color in $btn-colors {
    $text-color: btn-contrast($color);
    &-#{$type} {
      color: $text-color;
      background: $color;

      &:hover {
        color: $text-color;
        background: darken($color, 7%);
      }
      &:active,
      &:focus {
        outline: 0;
        box-shadow: 0 0 2px 1px rgba(darken($color, 15%), 0.5);
      }
      &,
      &:hover,
      &:active,
      &:focus {
        transition: color $default-transition, background-color $default-transition, border $default-transition;
      }
    }
  }
}
