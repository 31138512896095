
// Icons: AUTOMATICALLY GENERATED BY GULP
// --------------------------------------------------

.icon:before, [class^="icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:before, [class*=" icon-"]:after {
	display: inline-block;
	font-family: "icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1;
}

.icon-anchor:before,
.icon-arrow-down:before,
.icon-arrow-first:before,
.icon-arrow-last:before,
.icon-arrow-left:before,
.icon-arrow-long-down:before,
.icon-arrow-right:before,
.icon-arrow-up:before,
.icon-attachment:before,
.icon-check:before,
.icon-close:before,
.icon-cycle:before,
.icon-date:before,
.icon-download:before,
.icon-edit:before,
.icon-error:before,
.icon-extlink:before,
.icon-help:before,
.icon-home:before,
.icon-info:before,
.icon-link:before,
.icon-lock-open:before,
.icon-lock:before,
.icon-mail:before,
.icon-marker:before,
.icon-minus:before,
.icon-mobile-menu:before,
.icon-phone:before,
.icon-radio:before,
.icon-reload:before,
.icon-search:before,
.icon-social-facebook:before,
.icon-social-googleplus:before,
.icon-social-linkedin:before,
.icon-social-twitter:before,
.icon-status:before,
.icon-time:before,
.icon-user:before,
.icon-warning:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


.icon-anchor:before {
	content: "\E001";
}

.icon-arrow-down:before {
	content: "\E002";
}

.icon-arrow-first:before {
	content: "\E003";
}

.icon-arrow-last:before {
	content: "\E004";
}

.icon-arrow-left:before {
	content: "\E005";
}

.icon-arrow-long-down:before {
	content: "\E006";
}

.icon-arrow-right:before {
	content: "\E007";
}

.icon-arrow-up:before {
	content: "\E008";
}

.icon-attachment:before {
	content: "\E009";
}

.icon-check:before {
	content: "\E00A";
}

.icon-close:before {
	content: "\E00B";
}

.icon-cycle:before {
	content: "\E00C";
}

.icon-date:before {
	content: "\E00D";
}

.icon-download:before {
	content: "\E00E";
}

.icon-edit:before {
	content: "\E00F";
}

.icon-error:before {
	content: "\E010";
}

.icon-extlink:before {
	content: "\E011";
}

.icon-help:before {
	content: "\E012";
}

.icon-home:before {
	content: "\E013";
}

.icon-info:before {
	content: "\E014";
}

.icon-link:before {
	content: "\E015";
}

.icon-lock-open:before {
	content: "\E016";
}

.icon-lock:before {
	content: "\E017";
}

.icon-mail:before {
	content: "\E018";
}

.icon-marker:before {
	content: "\E019";
}

.icon-minus:before {
	content: "\E01A";
}

.icon-mobile-menu:before {
	content: "\E01B";
}

.icon-phone:before {
	content: "\E01C";
}

.icon-radio:before {
	content: "\E01D";
}

.icon-reload:before {
	content: "\E01E";
}

.icon-search:before {
	content: "\E01F";
}

.icon-social-facebook:before {
	content: "\E020";
}

.icon-social-googleplus:before {
	content: "\E021";
}

.icon-social-linkedin:before {
	content: "\E022";
}

.icon-social-twitter:before {
	content: "\E023";
}

.icon-status:before {
	content: "\E024";
}

.icon-time:before {
	content: "\E025";
}

.icon-user:before {
	content: "\E026";
}

.icon-warning:before {
	content: "\E027";
}


