//
// MEDIA
// ---------------------------------------------------------------------
// This file contains styling for video's, images, ...
//

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;

  &[src$=".svg"][onerror] {
    max-width: 100%;
    width: 100%; // Needed for correct scaled .svg's in IE9+
  }
  a & {
    border: 0px none;
  }
}

p img {

  &[style*="float:left"],
  &[style*="float: left"],
  &.float-left {
    margin-right: 1rem;
  }

  &[style*="float:right"],
  &[style*="float: right"],
  &.float-right {
    margin-left: 1rem;
  }

}

a {
  > img {
    transition: all $default-transition;
    &:hover, &:focus {
      box-shadow:
      1px 1px $color-primary,
      2px 2px $color-primary,
      3px 3px $color-primary;
      transform: translateX(-3px);
    }
  }
}