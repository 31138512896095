//
//  SEARCH
//

.block-search {
  display: none;
  margin-bottom: 0;

  @include md {
    display: block;
  }

  .search-form {
    margin-bottom: 0;
  }

  .container-inline {
    display: table;
    width: 100%;

    .form-item label {
      display: none;
    }

  }

  form {
    width: 100%;
    display: block;
    position: relative;
    min-width: 17.3rem;
  }

  .form-type-searchfield {
    display: table-cell;
    width: 100%;
  }

  input.form-search {
    width: 100%;
    border-radius: 0;
    padding-right: 2.5rem;

    &:hover, &:focus {
      border: 1px solid $color-primary;
      box-shadow: none;
    }

  }

  input.form-submit {
    background: url(../images/btn-search.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1rem 1rem;
    background-color: transparent;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: 0;
    min-width: 2.5rem;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    &:hover, &:focus {
      border: none;
      box-shadow: none;
    }

  }
}

.no-svg .block-search input.form-submit {
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.l-region--offcanvas {

  .block-search {
    float: none;
    margin: auto;
    padding: 1rem;
    display: block;
  }
  form {
    min-width: 100%;
  }
  input.form-search {
    background-color: $color-white !important;
    border: 0;
    
  }
}

// AUTOCOMPLETE
.ac_odd {
  background-color: lighten($color-primary, 35);
}
.ac_over{
  background-color: lighten($color-primary, 25);
  color: $default-text-color;
}

//page-search
#block-system-main {
  > div > h2,
  .ds-search-extra {
    @extend .small-container;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ds-search-extra {
    margin-bottom: 2rem;
  }
}