//
// NODES
// ---------------------------------------------------------------------
// This file contains styling for nodes:
// - Fields
// - Table cells
// - View modes (Display suite)
// - ...
//

//
// FIELDS
//
.field-name-node-link {
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    @extend .button-read-more;
  }
}

.field-name-field-attachments a, a.button-download {
  @extend .btn;
  @extend .btn-small;
  @extend .btn-primary;
  background-color: $color-grey;

  padding-left: 2.5rem;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;

  @include icon('minus', before) {
    @include font-size(12);
    position: absolute;
    left: 1rem;
    bottom: 0;
  }
  @include icon('arrow-long-down', after) {
    @include font-size(12);
    position: absolute;
    left: 1rem;
    bottom: 0.625rem;

    transition: bottom $slow-transition;
    transform: $scale3d;
  }

  &:focus, &:hover {
    &:after {
      bottom: 0.4rem;
      transform: scale(1);
    }
  }

}

.field-type-text-long,
.field-name-body {
  //Lists
  ol {
    @include reset-list;
    @include auto-numbers(li, ". ", item, true);
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
    ol {
      margin-bottom: 0.3rem;
    }
    li {
      li {
        padding-left: 0.5rem;
      }
      &:before {
        color: $color-dark;
        font-weight: bold;
      }
    }

  }
  ul {
    @include reset-list;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
    ul {
      margin-bottom: 0.3rem;
    }
    li {
      padding-left: 1rem;
      position: relative;
      &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        content: '\2022';
        margin-right: 0.6rem;
      }

    }
  }
  a {
    font-family: $default-font;
  }
}

.field-name-body {
  clear: both;
  padding: 1rem 0;

  @include md {
    padding: 2rem 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.field-name-field-introduction {
  a {
    color: $default-text-color;
    text-decoration: none;
    font-family: $default-font;
  }
}

.field-name-field-attachments {
  @extend .small-container;
  margin-top: 2rem;

  .field-label {
    @include font-size(16);
    color: $color-heading;
    font-family: $heading-font;
    font-weight: normal;
    margin: 0 0 1.5rem 0;
    border-bottom: 2px solid $color-primary;

  }

  .field-item {
    & + .field-item {
      margin-top: 1rem;
    }
  }
  img {
    display: none;
  }

}

//
// VIEW MODES
//

//UNPUBLISHED
.node-unpublished {
  background-color: transparent;
  position: relative;

  .article-inner-wrapper:after {
    content: "";
    background: transparent url('../images/draft.png') repeat 100%;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
  .ds-header {
    background-color: transparent !important;
  }
}

///
/// TEASERS
///

.view-mode-teaser, .view-mode-big_teaser, .view-mode-search_result {
  .teaser-inner-wrapper {
    overflow: hidden;
    height: 100%;
  }

  .field-name-field-introduction a,
  .field-name-search-snippet {
    transition: color $ultra-slow-transition;
  }

  &:hover, &:focus {
    .field-name-field-introduction a,
    .field-name-search-snippet {
      color: $color-black;
    }
  }
}

///
///
/// Teaser
///
///
.view-mode-teaser {
  outline: solid $color-border 1px;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;
  //transition: outline $easeInOut 0.4s;

  .teaser-inner-wrapper {
    outline: solid transparent 1rem;
    overflow: hidden;
    padding: 1.625rem;

    @include sm {
      .field-name-node-link {
        filter: alpha(opacity=0);
        opacity: 0;
        transition: opacity $slow-transition-time, transform $slow-transition-time;
        transform: $transform-left-right
      }

      &:before, &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        transition: opacity $slow-transition-time, transform $slow-transition-time;
        z-index: -1;
      }
      &:before {
        border-top: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;
        transform: $scale-height;
      }
      &:after {
        border-right: 1px solid $color-primary;
        border-left: 1px solid $color-primary;

        transform: $scale-width;
      }
    }

  }

  .field-name-title {
    h2 a, h3 a, h2, h3 {
      @include font-size(16);
    }
  }

  &:hover, &:focus {
    @include sm {
      outline: solid transparent 1px;
    }

    .teaser-inner-wrapper {
      @include sm {
        .field-name-node-link {
          filter: alpha(opacity=100);
          opacity: 1;
          transition-delay: 0s;
          transform: $scale3d
        }

        &:after, &:before {
          filter: alpha(opacity=100);
          opacity: 1;
          transform: scale(1);
        }
      }

    }
    .field-name-title {
      h2 a, h3 a, h2, h3 {
        color: $color-primary;
      }
    }
  }

  @include between($sm-width, $md-width) {
    margin-top: 0;
    &:nth-child(n+3) {
      margin-top: 1rem;
    }
    @include span(6 of 12) {
      &:nth-child(2n) {
        @include last;
      }
    }
  }
  @include md {
    margin-top: 0;
    width: 31.5%;
    float: left;
    margin-right: 2.75%;
    &:nth-child(n+4) {
      margin-top: 2rem;
    }
    &:nth-child(3n) {
      @include last;
    }
  }

}

///
///
/// BIG TEASER
///
///
.view-mode-big_teaser, .view-mode-search_result {
  cursor: pointer;

  & + .view-mode-big_teaser {
    margin-top: 1rem;

    @include md {
      margin-top: 2rem;
    }
  }

  .field-name-title {
    h2 a {
      position: relative;
      display: block;
      padding: 0.5rem 0;

      @extend .slide-right-left;
    }
  }
}

//
//
// FULL
//
//
.view-mode-full {

  .field-name-body {
    @extend .small-container;
    padding-bottom: 0 !important;
  }

  .field-name-field-blocks {
    padding-top: 1rem;

    @include sm {
      padding-top: 1.5rem;
    }

    @include md {
      padding-top: 3rem;
    }
  }

  .page-static-title {
    @include md {
      padding-left: 1rem;
      margin-bottom: 0rem;
      width: 100%;

      h1 {
        margin-bottom: 0;
        padding-bottom: 1rem;
        background-color: transparent;
      }
    }
  }

  .page-static-body {
    @include md {
      padding-left: 1rem;
      @include span(10 of 12);
    }

    @include lg {
      @include span(5 of 12);
    }

    & + .field-name-field-sidebar {
      @include md {

        @include span(10 of 12);
      }

      @include lg {
        @include span(7 of 12 last);
      }
    }
  }

  .field-name-field-sidebar {
    @include span(7 of 12);
  }

  .field-name-field-attachments {
    @extend .small-container;
    padding-top: 0;
    padding-bottom: 0;
  }

  > .article-inner-wrapper {

    .field-name-field-image {
      margin: 1rem 0;

      @include md-max {
        overflow-y: hidden;
        max-height: 8rem;

        img {
          position: relative;
          top: -2.5rem;
        }
      }

      @include md {
        @include span(5 of 12 last);
        position: relative;
        padding-left: 1rem;
        &:before {
          content: "";
          background-color: $color-grey-lighter;
          width: 50px;
          left: 0rem;
          top: -1rem;
          bottom: -1rem;
          position: absolute;
          z-index: 0;

        }
        img {
          border: 1rem solid $color-grey-light;
          position: relative;
          z-index: 1;
        }
      }
    }

    > .ds-header {
      background-color: $color-grey-light;
      padding: 1rem;

      @include sm {
        padding: 1.5rem;
      }
      @include md {
        padding: 3rem;
      }

      .field-name-field-introduction {
        font-style: italic;
      }

      .field-name-title,
      .field-name-field-introduction {
        @include md {
          @include span(7 of 12);
          padding-right: 4rem;
        }
      }
      .field-name-title, .field-name-field-image {
        @include md-max {
          text-align: center;
        }
      }
    }
  }
}

//Search
.view-mode-search_result {
  @extend .small-container;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &.view-mode-search_result {
    margin-top: 2rem;
  }

  em {
    color: $color-black;
    font-weight: bold;
  }

}

///
///
/// Homepage
///
///

.view-mode-homepage {
  > .article-inner-wrapper {
    .field + .field,
    .ds-center + .ds-footer,
    .ds-header + .ds-center,
    .ds-header + .ds-footer {
      margin-top: 1rem;

      @include md {
        margin-top: 2rem;
      }
    }
    .ds-header {
      .field-name-field-introduction {
        font-style: italic;
      }
    }

  }

  .field-name-field-image {
    margin-bottom: 1rem;

  }

}