//
// NAVIGATION
// ---------------------------------------------------------------------
// This file contains the global menu styles
// - Main menu
// - Region specific menu's like footer menu
//

// MAIN MENU - HORIZONTAL

//
// NAVIGATION
// ---------------------------------------------------------------------
// This file contains the global menu styles
// - Main menu
// - Region specific menu's like footer menu
//
.l-wrapper {
  @include md-max {
    left: 0;
    position: relative;
    transition: all $default-transition;
    transform: translate3d(0, 0, 0);
    &.menu-open {
      transform: translate3d(-16.25rem, 0, 0);
    }
  }

}

//MAIN MENU
.navigation nav {
  display: none;
  position: relative;
  margin-bottom: 2.3125rem;

  @include md {
    display: block;
  }

  a {
    color: $color-white;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
  }
  ul {
    @include reset;
    background-color: $color-dark;
  }

  ul li {
    @include reset-list;
    float: left;
    &.home-active {
      a {
        background-color: darken($color-primary, 10) !important;
      }
    }

    a.active {
      background-color: darken($color-primary, 10) !important;
    }

    &.active-trail, &:hover, &:focus {
      background-color: $color-primary;
      a {
        background-color: $color-primary;

        &:after {
          margin-top: -10px;
        }

      }
      > a {
        background-color: darken($color-primary, 10);
      }

      ul {
        filter: alpha(opacity=100);
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
    }

    &.hover {
      ul {
        filter: alpha(opacity=100) !important;
        opacity: 1 !important;
        visibility: visible !important;
        transition-delay: 0s !important;
      }
    }

    a {
      @include font-size(16);
      padding-right: 2rem;

      position: relative;
      &:before {
        content: "";
        background-color: $color-white;
        width: 2px;
        height: 100%;
        color: $color-white;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &:after {
        content: "\25BE";
        position: absolute;
        right: 0.875rem;
        top: 50%;
        margin-top: -12px; //font-size/2
      }

    }

    ul {
      background-color: $color-primary;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      visibility: hidden;
      filter: alpha(opacity=0);
      opacity: 0;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

      li {

        a {
          @include font-size(14);

          &:hover, &:focus {
            background-color: darken($color-primary, 10);
          }
          &:after, &:before {
            content: none !important;
          }
        }
      }
    }
  }
}

// Mobile Main Menu
.l-region--offcanvas nav {
  ul.menu {
    margin: 0;
    padding: 1rem;
    li {
      @include reset-list;
      a {
        @include font-size(15);
        border-bottom: 1px solid darken($color-grey, 10%);
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        display: block;
        color: $color-white;
        text-decoration: none;

        &.active, &:hover, &:focus, &:active {
          color: lighten($color-grey, 15);
        }
      }
      ul {
        padding: 0 1rem;
        li {
          a {
            @include font-size(14);
            color: $color-grey;
          }
        }
      }
    }
  }
}

//MOBILE TOGGLE
.mobile-menu-toggle {
  float: right;
  position: relative;
  top: 1rem;

  @include icon(mobile-menu) {
    cursor: pointer;
    @include font-size(28, 0);
    color: $color-primary;
  }

  .menu-open & {
    &:before {
      @include font-size(34, 0);
    }
  }

  &:after {
    content: "";
    display: block;
    border-left: 1px solid $color-primary;
    position: absolute;
    left: -1rem;
    top: 50%;
    height: 2.5rem;
    margin-top: -1.25rem; //height divided by 2
  }

  @include md {
    display: none;
  }

  a[href*="#"] {
    &:before {
      content: none;
    }
  }
}

// QUICK LINKS
.quick-links {
  padding-left: 1rem;
  padding-right: 1rem;

  @include md {
    padding-right: 2rem;
  }

  h2 {
    border-bottom: 3px solid $color-primary;
  }

  ul.menu, li, li.leaf {
    @include reset;
    @include reset-list;
  }

  ul.menu {
    li {
      position: relative;

      & + li {
        //margin-top: 0.875rem;
      }

      @extend .list-effect;
    }
  }
}

//BOTTOM MENU

.region-inner-bottom {
  ul.menu, ul.menu li {
    @include reset-list;
    @include reset;
  }
  .menu-block-wrapper {
    position: relative;
    padding: 0.6rem;
    @include sm-only {
      max-width: span(9 of 12);
    }
    @include sm {
      margin-top: 2rem;
    }

    &:after, &:before {
      content: "";
      position: absolute;
      top: 0rem;
      width: 3.6rem;
      height: 3.6rem;
      z-index: -1;
      background-color: $color-grey-lighter;
    }

    &:after {
      right: 0rem;
    }

    &:before {
      left: 0rem;
    }

    > ul.menu {
      background-color: $color-white;
      padding: 2rem;
    }
    //ALL A tags
    > ul.menu li a {
      text-decoration: none;
      color: $color-black;
      font-family: $default-font;
      font-weight: bold;
    }

    // FIRST LEVEL LI
    > ul.menu > li {
      @include between(0, $md-width) {
        + li {
          margin-top: 2rem;
        }
      }

      @include md-only {
        margin-top: 2rem;
        padding: 1rem;
        @include span(6 of 12);
        &:nth-child(1), &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(2n) {
          @include last;
        }
      }
      @include lg {
        width: 22.90%;
        float: left;
        margin-right: 2.75%;
        &:nth-child(4n) {
          @include last;
        }
      }
    }
    //FIRST LEVEL A
    > ul.menu > li > a {
      @include font-size(16);
      color: $color-dark;
      font-family: $heading-font;
      font-weight: normal;

      display: block;
      margin-bottom: 0.5rem;
      padding-bottom: 0.25rem;
      overflow-x: hidden;
      position: relative;

      @extend .slide-right-left;

    }
    //every li followed by li in nested level
    > ul.menu > li > ul > li + li {
      a {
        padding-top: 0.325rem;
        display: block;
      }

    }

    > ul.menu li ul li {
      a {
        position: relative;
        @include font-size(12);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// COPYRIGHT
.region-inner-footer {
  border-top: 2px solid $color-border;
  padding: 1rem 0;
  ul.menu, ul.menu li {
    @include reset-list;
    @include reset;
  }

  ul.menu {
    position: relative;
    padding-left: 3rem;
    padding-top: 0.531rem;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 2.625rem;
      width: 2.625rem;
      background: transparent url('../images/logo-small.png') no-repeat;
    }
  }

  ul.menu li {
    float: left;
    &:after {
      content: "\007C";
      margin: 0 0.25rem;

    }
  }

  ul.menu li a {
    @include reset;
    text-decoration: underline;
    font-family: $bold-font;
    @include font-size(12);

    &:hover {
      text-decoration: none;
    }
  }

}