//
// MIXINS
// ---------------------------------------------------------------------
// This file contains compass mixins:
// - compatibility shorthands
// - REM fallbacks
// - ...
//


@mixin auto-numbers($numbered-element, $sep, $counter: item, $nested-parent: false ){
  $sel: ();
  @if $nested-parent {
    $sel: append($sel, $nested-parent);

    #{$nested-parent}{
      list-style: none;
      margin-left: 0;
    }
  }
  $sel: append($sel, unquote('&'), comma);

  #{$sel}{
    counter-reset: #{$counter};
    > #{$numbered-element}{
      &:before{
        counter-increment: #{$counter};
        content: if($nested-parent, counters(#{$counter}, "#{$sep} ") "#{$sep} ", counter(#{$counter}) "#{$sep} ") ;
      }
    }
  }
}

// CLEARFIX
// With better IE support

@mixin clearfix {
  // For modern browsers
  &:before,
  &:after {
    content:" ";
    display:table;
  }
  &:after {
    clear:both;
  }
  // For IE 6/7 (trigger hasLayout)
  & {
    *zoom:1;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  list-style-image: none;
}

@mixin reset {
  padding: 0;
  margin: 0;
}

// APPEARANCE
// https://github.com/postcss/autoprefixer#why-doesnt-autoprefixer-support-appearance
@mixin appearance($style) {
  -moz-appearance: $style;
  -webkit-appearance: $style;
  appearance: $style;
}

// Buttons
@mixin button($type: NULL, $color: NULL) {
  @extend .btn;

  @if $type != NULL {
    @extend .btn-#{$type};
  }
  @if $color != NULL {
    @extend .btn-#{$color};
  }
  @if $color == NULL and $type == NULL {
    @extend .btn-#{$default-btn-type};
    @extend .btn-#{$default-btn-color};
  }
}

// FONT SIZING
// Adds a default line-height for a font-size.
@mixin font-size($values...) {
  $max: length($values);
  $font-size: 16 !default;
  $line-height: false !default;

  // If font-size is supplied, override default
  @if $max != 0 {
    $font-size: nth($values, 1);
  }
  // If line-height is supplied, override default
  @if $max > 1 and type-of(nth($values, 2)) == number {
    $line-height: nth($values, 2);
  }
    // Else calculate line-height using $font-size
  @else if $line-height == false {
    $line-height: $font-size * 1.5;
  }
  // Output supplied values
  @if $max != 0 {
    @if nth($values, $max) == "!important" {
      font-size: $font-size / 16 + rem !important;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem !important;
      }
    }
    @else {
      font-size: $font-size / 16 + rem;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem;
      }
    }
  }
    // Else output default values
  @else {
    font-size: $font-size / 16 + rem;
    line-height: $line-height / 16 + rem;
  }
}

// FONT SMOOTHING
// Better text rendering for modern browsers

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// FONT

@mixin font($family: $default-font, $weight: 400, $size: false) {
  // Reset font-family if only weight is passed as parameter.
  @if type-of($family) == number {
    $weight: $family;
    $family: $default-font;
  }
  font-family: $family;
  font-weight: $weight;
  @if $size {
    @include font-size($size, 0);
  }
}

// ICONS
// Add icon font character to element

@mixin icon($icon, $position: "before", $replace: false, $breakpoint: false) {
  @if $replace {
    font-size: 0;
  }
  &:#{$position} {
    @extend .icon-#{$icon}:before;
    font-family: $icon-font;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    @if $replace and $breakpoint == false {
      font-size: 1rem;
    } @else if $breakpoint {
      font-size: 0;
    }
    @content;
  }
  @if $breakpoint {
    @include susy-breakpoint($breakpoint){
      &:#{$position} {
        @include font-size($default-font-size, 0);
      }
    }
  }
}

// PANEL COLS
// Include panel layouts with x colums, depending on sidebars

@mixin panel-cols($cols) {
  .panel-cols-#{$cols} {
    @include sm {
      .panel-panel {
        @include span(floor($sm-columns/$cols) of $sm-columns);
      }
      .panel-panel.last {
        @include span(floor($sm-columns/$cols) last of $sm-columns);
      }
      .sidebar-first &,
      .sidebar-second &,
      .two-sidebars {
        .panel-panel {
          @include span(floor(($sm-columns - $sm-sidebar-columns)/$cols) of $sm-columns - $sm-sidebar-columns);
        }
        .panel-panel.last {
          @include span(floor(($sm-columns - $sm-sidebar-columns)/$cols) last of $sm-columns - $sm-sidebar-columns);
        }
      }
    }
    @include lg {
      .panel-panel {
        @include span(floor($lg-columns/$cols) of $lg-columns);
      }
      .panel-panel.last {
        @include span(floor($lg-columns/$cols) last of $lg-columns);
      }
      .sidebar-first &,
      .sidebar-second & {
        .panel-panel {
          @include span(floor(($lg-columns - $lg-sidebar-columns)/$cols) of $lg-columns - $lg-sidebar-columns);
        }
        .panel-panel.last {
          @include span(floor(($lg-columns - $lg-sidebar-columns)/$cols) last of $lg-columns - $lg-sidebar-columns);
        }
      }
      .two-sidebars & {
        .panel-panel {
          @include span(floor(($lg-columns - ($lg-sidebar-columns*2))/$cols) of $lg-columns - ($lg-sidebar-columns*2));
        }
        .panel-panel.last {
          @include span(floor(($lg-columns - ($lg-sidebar-columns*2))/$cols) last of $lg-columns - ($lg-sidebar-columns*2));
        }
      }
    }
  }
}

// TOOLTIP ARROWS
// Creates a centered tooltip arrow
// Usage: @include tooltip-arrow(bottom, $color-primary, 10px);

@mixin tooltip-arrow($position: right, $color: red, $size: '5px') {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    border: solid;
    border-width: $size;
    border-color: $color;
    @if ($position == top) {
      left: 50%;
      top: -$size;
      margin-left: -$size;
      border-top-width: 0px;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    @if ($position == right) {
      top: 50%;
      right: -$size;
      margin-top: -$size;
      border-right-width: 0px;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
    @if ($position == bottom) {
      left: 50%;
      bottom: -$size;
      margin-left: -$size;
      border-bottom-width: 0px;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    @if ($position == left) {
      top: 50%;
      left: -$size;
      margin-top: -$size;
      border-left-width: 0px;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }
}
