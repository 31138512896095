// Susy Box Sizing
// =================

// Global Box Sizing
// -----------------
// Set a box model globally on all elements.
// - [$box]: border-box | content-box
// - [$inherit]: true | false
@mixin global-box-sizing(
  $box: susy-get(global-box-sizing),
  $inherit: false
) {
  $inspect: $box;

  @if $inherit {
    @at-root {
       html { @include output((box-sizing: $box)); }
       *, *:before, *:after { box-sizing: inherit; }
     }
  } @else {
    *, *:before, *:after { @include output((box-sizing: $box)); }
  }

  @include susy-inspect(global-box-sizing, $inspect);
  @include update-box-model($box);
}

// Border Box Sizing
// -----------------
// A legacy shortcut...
// - [$inherit]: true | false
@mixin border-box-sizing(
  $inherit: false
) {
  @include global-box-sizing(border-box, $inherit);
}

// Update Box Model
// ----------------
// PRIVATE: Updates global box model setting
@mixin update-box-model(
  $box
) {
  @if $box != susy-get(global-box-sizing) {
  @include susy-set(global-box-sizing, $box);
  }
}
