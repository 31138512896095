//IFRAME
.cke_editable {
  padding-left: 1rem;
  padding-right: 1rem;
}

// CKEditor
.width-25 {
  width: 100%;
  @include sm {
    width: span(8 of 12);
    float: left;
    margin-right: 1rem;
  }
  @include md {
    width: span(6 of 12);
  }
  @include lg {
    width: span(3 of 12);
  }
}

.width-50 {
  width: 100%;
  @include md {
    width: span(7 of 12);
    float: left;
    margin-right: 1rem;
  }
  @include lg {
    width: span(6 of 12);
  }
}

.width-75 {
  @include lg {
    width: span(9 of 12);
    float: left;
    margin-right: 1rem;
  }
}

h2, h3, h4, h5, h6 {
  &.underline {
    border-bottom: 2px solid $color-primary;
  }
}

ul, p {
  &.special {
    border: 1px solid $color-border;
    padding: 1rem;
    background-color: $color-grey-light;
  }
}
.special-primary {
  border: 1px solid $color-border;
  padding: 1rem;
  background-color: $color-primary;
  color: $color-white;
}
.button-small {
  @extend .btn;
  @extend .btn-small;
  @extend .btn-primary;
}
.button-medium {
  @extend .btn;
  @extend .btn-medium;
  @extend .btn-primary;
}
.button-large {
  @extend .btn;
  @extend .btn-large;
  @extend .btn-primary;
}

.border-block {
  border: 1px solid $color-border;
  padding: 1.625rem;
  margin-bottom: 2rem;

  ul, ol {
    margin-bottom: 0;
  }
}

hr {
  margin: 2rem 0 2rem 0;
  border: 1px solid $color-border;
}