//
// BREADCRUMB
// ---------------------------------------------------------------------
// This file contains styling for the breadcrumb.
//

ul.breadcrumb {
  @include reset-list;
  padding-left: 1rem;
  li {
    float: left;
    @include font-size(12);

    a {
      color: $color-black;
      text-decoration: none;
      font-family: $default-font;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  li:not(.last) {
    @include icon('arrow-right', after) {
      margin: 0 0.25rem;
    }
  }
}