//
// TYPOGRAPHY
// ---------------------------------------------------------------------
// This file contains styling for typographic elements:
// - headings
// - paragraphs
// - links
// - modifier classes like p.small, description, superscript, ...
//

h1, h2, h3, h4, h5,
h1 a, h2 a, h3 a, h4 a, h5 a {
  color: $color-heading;
  font-family: $heading-font;
  margin: 0 0 1rem 0;
  text-decoration: none;
  font-weight: normal;

  &:hover {
    text-decoration: none;
  }
}

h1 {
  @include font-size(24);
  margin-bottom: 1rem;
  @include sm {
    @include font-size(32);
    margin-bottom: 2rem;
  }
}

h2 {
  @include font-size(16);
  @include sm {
    @include font-size(18);
  }
}

h3 {
  @include font-size(14);
  @include sm {
    @include font-size(16);
  }
}

p {
  margin: 0 0 1rem 0;
}


a {
  color: $color-link;
  text-decoration: none;
  font-family: $bold-font;
  font-weight: normal;

  &:hover {
    color: $color-primary;
    text-decoration: underline;
  }
  &, &:after, &:before {
    transition: color $default-transition, background-color $default-transition, border $default-transition;

  }
  &[href*='#'] {
    font-family: $default-font;
    @include icon('anchor', before) {
      margin-right: 0.4rem;
      text-decoration: none;
      color: $color-black;
    }
  }

  .ui-datepicker-calendar & {
    &:before {
      content: none;
    }
  }
}

a span.ext {
  @include icon('extlink') {
    color: $color-muted;
    margin-left: 0.2rem;
  }
}

ul, ol {
  padding-left: 2rem;
  li {
    a[href*='#'] {
      &:before {
        content: none;
      }
    }
  }
}

div.description {
  @include font-size(14);
  color: $color-muted;
}
