//
// MESSAGES
// ---------------------------------------------------------------------
// This file contains styling for status, warning and error messages.
//

div.messages {
  background-image: none;
  background-color: $color-white;
  border: none;
  margin: 2rem -1rem;
  padding: 1rem 1rem 1rem 5rem;
  min-height: 56px;
  position: relative;

  &.status {
    @include icon(status) {
      background: $color-status;
    }
    background-color: lighten($color-status, 20);
    border-right: 5px solid $color-status;
  }
  &.warning {
    @include icon(warning) {
      background: $color-warning;
    };

    border-right: 5px solid $color-warning;
    background-color: lighten($color-warning, 20);

  }
  &.error {
    @include icon(error) {
      background: $color-error;
    }
    color: $color-white !important;
    border-right: 5px solid $color-error;
    background-color: lighten($color-error, 20);

  }
  &.status,
  &.warning,
  &.error {
    color: $default-text-color;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      min-width: 56px;
      min-height: 56px;
      color: $color-white;
      font-size: 1.5rem;
      padding: 1rem;
    };
  }

  ul {
    li {
      list-style: square;
    }
  }
}
