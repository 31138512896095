//
// LAYOUT: CALINERRA
// ---------------------------------------------------------------------
// This file contains all region and positions related css.
// IMPORTANT : All susy variables are set in 'config/_layout.scss'.
//
.region-inner-toolbar,
.region-inner-header,
.region-inner-navigation,
.region-inner-messages,
.region-inner-top,
.region-inner-breadcrumb,
.region-inner-main,
.region-inner-bottom,
.region-inner-footer {
  @include container(); // Define these elements as the grid containers.
  margin-bottom: 1rem;
  padding-left: $grid-padding;
  padding-right: $grid-padding;
  box-sizing: content-box;
}

.l-region--highlighted,
.l-region--help,
.l-region--sidebar-first,
.l-region--sidebar-second {
  margin-bottom: 1rem;
}

.l-content .region-inner {
  clear: both;
}

.l-branding {
  padding-right: 4rem;
}

.region-inner-header {
  max-width: $container + 2rem !important;
  margin-bottom: 2rem;
}

@include md-max {
  .region-inner-header, .region-inner-navigation {
    margin-bottom: 0;
  }
  .region-inner-header {
    //margin-bottom: -1rem;
  }
}

.region-inner-breadcrumb {
  margin: 2rem auto;
}

//
// EXTRA SMALL
//

@include xs {
  .region-inner-toolbar,
  .region-inner-header,
  .region-inner-navigation,
  .region-inner-top,
  .region-inner-breadcrumb,
  .region-inner-messages,
  .region-inner-main,
  .region-inner-bottom,
  .region-inner-footer {
    @include with-layout($xs-columns) {
      @include container();
    }
  ;
  }
}

//
// SMALL
//

@include sm {
  .region-inner-toolbar,
  .region-inner-header,
  .region-inner-navigation,
  .region-inner-top,
  .region-inner-breadcrumb,
  .region-inner-messages,
  .region-inner-main,
  .region-inner-bottom,
  .region-inner-footer {
    @include with-layout($sm-columns) {
      @include container();
    }
  ;
  }
}

//
// MEDIUM
//

@include md {
  .region-inner-toolbar,
  .region-inner-header,
  .region-inner-navigation,
  .region-inner-top,
  .region-inner-breadcrumb,
  .region-inner-messages,
  .region-inner-main,
  .region-inner-bottom,
  .region-inner-footer {
    @include with-layout($md-columns) {
      @include container();
    }
  ;
  }

  .l-region--navigation {
    clear: both;
  }

  .sidebar-first,
  .sidebar-second,
  .two-sidebars {
    .l-content {
      @include span(($md-columns - $md-sidebar-columns) of $md-columns);
    }
    .l-region--sidebar-first,
    .l-region--sidebar-second {
      @include span($md-sidebar-columns last of $md-columns);
    }
    .l-region--sidebar-second {
      clear: right;
    }
  }

  .region-inner-header {
    display: table;
  }
  .l-branding, .l-region--header {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
  .l-branding {
    padding-right: 0;
  }

}

//
// LARGE
//

@include lg {
  .region-inner-toolbar,
  .region-inner-header,
  .region-inner-navigation,
  .region-inner-top,
  .region-inner-breadcrumb,
  .region-inner-main,
  .region-inner-messages,
  .region-inner-bottom,
  .region-inner-footer {
    @include with-layout($lg-columns) {
      @include container();
    }
  ;
  }

  .sidebar-first {
    .l-content {
      @include span(($lg-columns - $lg-sidebar-columns) last of $lg-columns);
    }
    .l-region--sidebar-first {
      @include span($lg-sidebar-columns of $lg-columns);
    }
  }
  .sidebar-second {
    .l-content {
      @include span(($lg-columns - $lg-sidebar-columns) of $lg-columns);
    }
    .l-region--sidebar-second {
      @include span($lg-sidebar-columns last of $lg-columns);
      clear: none;
    }
  }

  .two-sidebars {
    .l-content {
      @include span(($lg-columns - ($lg-sidebar-columns * 2)) of $lg-columns);
      @include push($lg-sidebar-columns of $lg-columns);
    }
    .l-region--sidebar-first,
    .l-region--sidebar-second {
      @include span($lg-sidebar-columns of $lg-columns);
    }
    .l-region--sidebar-first {
      @include pull(($lg-columns - $lg-sidebar-columns) of $lg-columns);
    }
    .l-region--sidebar-second {
      @include last;
      clear: none;
    }
  }
}

@include xl {
  .region-inner-toolbar,
  .region-inner-header,
  .region-inner-navigation,
  .region-inner-top,
  .region-inner-breadcrumb,
  .region-inner-messages,
  .region-inner-main,
  .region-inner-bottom,
  .region-inner-footer {
    @include with-layout($xl-columns) {
      @include container();
    }
  ;
  }
}
