//
// PANELS
// ---------------------------------------------------------------------
// This file contains styling for panel layouts and panes.
//

.panel-row {
  margin-bottom: 1rem;
}

.panel-pane {
  background: transparent;
  //border: $default-border;
  margin-bottom: 1rem;
  padding: 1rem 0;
  &.view-pane,
  &.pane-views {
    padding: 0;
  }
}

.panel-panel {
  margin-top: 1rem;
  &.line {
    @include reset;
  }
}
// COLUMN PANELS

/*@include panel-cols(2);
@include panel-cols(3);
@include panel-cols(4);
@include panel-cols(5);*/

// SIDEBAR PANEL

.panel-twocol-63-37-stacked {
  @include md {
    .panel-panel.left {
      @include span(8 of 12);
      border-right: 2px solid $color-border;
      padding-left: 1rem;
      padding-right: 2rem;
    }
    .panel-panel.right {
      @include span(4 of 12);
    }
    .panel-panel.lastUnit {
      @include last;
    }
  }

  @include lg {
    .panel-panel.left {
      @include span(9 of 12);
      border-right: 2px solid $color-border;
      padding-left: 2rem;
      padding-right: 6rem;
    }
    .panel-panel.right {
      @include span(3 of 12);
    }
    .panel-panel.lastUnit {
      @include last;
    }
  }
}
