// Susy Fallback Clearfix
// ======================


// Clearfix
// --------
// Check for an existing support mixin, or provide a simple fallback.
@mixin susy-clearfix {
  @if susy-support(clearfix, (mixin: clearfix)) {
    @include clearfix;
  } @else {
    &:after {
      content: " ";
      display: block;
      clear: both;
    }
  }
}
