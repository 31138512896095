//
// FEDERALE HEADER
//

.block-locale {
  padding: $gutter/4 $gutter $gutter/4 0;
  z-index: 10;
  display: inline-block;
  float: left;
  text-align: center;
  position: relative;

  @include sm {

  }

  ul,
  ul li {
    @include reset-list;
    padding: 0.3125rem 0;
    z-index: 11;
  }

  ul li {
    margin-right: 4px;
    text-align: center;
    display: block;
    padding: 0;
    float: left;

    a {
      @include font-size(11, 22);
      text-transform: uppercase;
      text-decoration: none;
      color: $color-federal-dark;
      width: 1.6rem;
      height: 1.375rem;
      display: block;
      background: $color-federal-light;
      border: 1px solid $color-federal-medium;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 300;
      padding: 0 0 3px 0;

      &.active {
        color: $color-white;
        background: $color-federal-dark;
        border: 1px solid $color-federal-medium;
      }
    }
  }
}

#ofed-federal-header-wrapper {
  min-height: 2.625rem;

  #ofed-federal-header-link {
    text-align: left;
    padding-left: $gutter;
    display: none;

    @include sm {
      float: right;
      display: inline-block;
    }
    span.ext {
      display: none;
    }
    a {
      color: $color-federal-dark;
    }
  }
}
