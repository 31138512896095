//
// FORMS
// ---------------------------------------------------------------------
// This file contains form styles:
// - Labels
// - Fields
// - (Collapsible) fieldsets
// - Submit/reset/cancel buttons
// - ...
//

.form-item {
  margin-bottom: 1rem;

  .description {
    margin-top: 0.5rem;
    @include font-size(12);
  }
}

.form-required {
  color: $color-error;
}

.form-disabled {
  opacity: .5;

  input {
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      border-color: $color-border;
      box-shadow: none;
    }
  }
}

.grippie {
  display: none;
}

// Labels

.form-item label {
  margin-bottom: 0.5;
}

form .label-inline,
form .webform-container-inline {
  @include sm {
    letter-spacing: -0.25rem; // Remove the space between inline elements

    > * {
      letter-spacing: 0px;
    }
    > label {
      width: 12rem;
      display: inline-block;
    }
    input {
      vertical-align: top;
    }
    .description {
      margin-left: 12rem;
    }
    .form-textarea-wrapper {
      display: inline-block;
    }
    &.form-type-textarea label {
      vertical-align: top;
    }
  }
}

// Fields

input,
textarea {
  max-width: 100%;
  border: 2px solid $color-border;
  border-radius: $form-field-border-radius;
  padding: 0.75rem;
  @include appearance(none);

  &:hover {
    outline: none;
    border-color: $form-hover-color;
    box-shadow: 0 0 2px 1px rgba($form-shadow-color, 0.15);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: $form-focus-color;
    box-shadow: 0 0 2px 1px rgba($form-focus-color, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
}

input {
  @include font-size(14, 0);
}

textarea {
  @include font-size(14, 22);
}

// File

input[type="file"] {
  line-height: 1;
  vertical-align: top;
  border-radius: $form-field-border-radius 0 0 $form-field-border-radius;
  border-width: 1px 0 1px 1px;
  @include font-size(11, 0); // Best cross-browser results

  + button {
    padding: 0.9375rem;
    border-radius: 0 $form-field-border-radius $form-field-border-radius 0;
    line-height: 1;
    @include font-size(14, 0); // line-height needs to be unit-less.
  }
}

// Image

input[type="image"] {
  vertical-align: middle;
}

// Radio & Checkbox

.form-type-radio,
.form-type-checkbox {
  input {
    @extend .element-invisible;
    &:focus + label:before,
    &:hover + label:before {
      box-shadow: 0 0 1px rgba($form-shadow-color, 0.15) inset, 0 1px 0 rgba($form-shadow-color, 0.075) inset, 0 0 0 1px rgba($form-shadow-color, 0.15) inset, 0 0 2px 1px rgba($form-shadow-color, 0.15);
    }
  }
  label {
    margin: 0;
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;

    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 0.25rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      box-shadow: 0 0 0 1px rgba($form-shadow-color, 0.26) inset;
    }
  }
}

.form-type-radio {
  label:before {
    border-radius: 50%;
  }
  input:checked + label:before {
    background: $color-primary url(../images/sprite.png) 0 0 no-repeat;
    background-size: auto;

    html.svg & {
      background: $color-primary url(../images/sprite.svg) 0 0 no-repeat;
    }
  }
}

.form-type-checkbox {
  label:before {
    border-radius: 0.125rem;
  }
  input:checked + label:before {
    background: $color-primary url(../images/sprite.png) 0 -16px no-repeat;
    background-size: auto;

    html.svg & {
      background: $color-primary url(../images/sprite.svg) 0 -16px no-repeat;
    }
  }
}

// Select

.form-type-select,
.webform-component-select {
  label {
    display: block;
    font-weight: bold;
  }
}

.form-select {

  padding: 0.9375rem 1.75rem 0.9375rem 1rem;
  @include appearance(none);
  border: 1px solid $color-dark;
  border-radius: $form-field-border-radius;
  background: $color-white url(../images/sprite.png) 95% -35px no-repeat;
  background-size: auto;
  display: inline-block;
  &:active,
  &:focus {
    outline: 0;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color-black;
  }
  &::-ms-expand {
    display: none;
  }
  html.ie & {
    @include appearance(normal);
    padding: 0;
    height: 2.5rem;
  }
  &:hover {
    outline: none;
    border-color: $form-hover-color;
    box-shadow: 0 0 2px 1px rgba($form-shadow-color, 0.15);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: $form-focus-color;
    box-shadow: 0 0 2px 1px rgba($form-focus-color, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
  html.svg & {
    background: $color-white url(../images/sprite.svg) 95% -35px no-repeat;
  }
  html.ie & {
    background-image: none;
  }

}

// Date

.form-type-date {
  .form-item {
    display: inline;
  }
  input[type="image"] {
    background: transparent;
    border: none;
  }
}

// Time

.form-type-webform-time {
  .form-type-select {
    display: inline;
  }
}

// Submit

.form-submit {
  @include button();
}

// Fieldsets

fieldset {
  padding: 1rem;
  border: $form-field-border;
  border-radius: $default-border-radius;

  html.js &.collapsible .fieldset-legend {
    padding-left: 0;
    background: none;
    @include icon('arrow-down') {
      color: $color-black;
      margin-right: 0.25rem;
    }
  }
}

// Clearable fields

.clearable-action {
  color: $color-muted;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  cursor: pointer;
  display: none;
  @include font-size(12, 0);
}

.clearable-wrapper {
  position: relative;
  display: inline-block;

  input {
    padding-right: 2rem;
  }
}

.node .webform-client-form {
  @extend .small-container;
}