//
// LAYOUT CONFIGURATION
// ---------------------------------------------------------------------
// This file contains layout and grid settings.
//

// Compass settings

$rhythm-unit: 'rem';

// Set consistent vertical and horizontal spacing units.

$horz-spacing-unit:   1rem;
$vert-spacing-unit:   $horz-spacing-unit;

// Grid settings

$gutter:              1rem;
$grid-padding:        1rem;
$container:           72.875rem;  // 1166px
$mobile-columns:      4;

// Extra Small
$xs-width:            20em;   // 320px
$xs-columns:          4;
$xs-sidebar-columns:  0;

// Small
$sm-width:            32.5em;   // 520px
$sm-columns:          8;
$sm-sidebar-columns:  0;

// Medium
$md-width:            48em;   // 768px
$md-columns:          12;
$md-sidebar-columns:  4;

// Large
$lg-width:            60em;   // 960px
$lg-columns:          12;
$lg-sidebar-columns:  4;

// Extra Large
$xl-width:            90em;   // 1440px
$xl-columns:          12;
$xl-sidebar-columns:  4;

// Column/Gutter ratio calculation
@function grid-ratio() {
  @return (($container - (($gutter * $xl-columns) - 1)) / $xl-columns);
}

// Susy settings (http://susydocs.oddbird.net/en/latest/settings)

$susy: (
  columns: $mobile-columns,           // <number> | <list>
  gutters: $gutter/grid-ratio(),      // <ratio>
  container: $container,              // <length> | auto
  global-box-sizing: border-box,      // border-box | content-box
  debug: (
    image: hide,                      // show | hide | show-columns | show-baseline
    color: rgba(red, 0.3),            // <color>
  ),
  use-custom: (
    rem: true,                        // true | false
    box-sizing: false,                // Prevents deprecation warnings
  )
);

//
// BREAKPOINTS
//

// MIN-WIDTH
@mixin xs {
  @include susy-breakpoint($xs-width) {
    @content;
  }
}

@mixin sm {
  @include susy-breakpoint($sm-width) {
    @content;
  }
}

@mixin md {
  @include susy-breakpoint($md-width) {
    @content;
  }
}

@mixin lg {
  @include susy-breakpoint($lg-width) {
    @content;
  }
}

@mixin xl {
  @include susy-breakpoint($xl-width) {
    @content;
  }
}

// MAX-WIDTH
@mixin xs-max {
  @include susy-breakpoint(max-width $xs-width) {
    @content;
  }
}

@mixin sm-max {
  @include susy-breakpoint(max-width $sm-width) {
    @content;
  }
}

@mixin md-max {
  @include susy-breakpoint(max-width $md-width) {
    @content;
  }
}

@mixin lg-max {
  @include susy-breakpoint(max-width $lg-width) {
    @content;
  }
}

@mixin xl-max {
  @include susy-breakpoint(max-width $xl-width) {
    @content;
  }
}

// ONLY

@mixin xs-only {
  @include susy-breakpoint($xs-width ($sm-width - (1 / 16))) {
    @content;
  }
}

@mixin sm-only {
  @include susy-breakpoint($sm-width ($md-width - (1 / 16))) {
    @content;
  }
}

@mixin md-only {
  @include susy-breakpoint($md-width ($lg-width - (1 / 16))) {
    @content;
  }
}

@mixin lg-only {
  @include susy-breakpoint($lg-width ($xl-width - (1 / 16))) {
    @content;
  }
}

// BETWEEN x AND y

@mixin between($min, $max) {
  @include susy-breakpoint($min ($max - (1 / 16))) {
    @content;
  }
}
