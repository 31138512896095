//
// DOCUMENT
// ---------------------------------------------------------------------
// This file contains the base styling for the HTML and BODY tag.
//

html, body {
  overflow-x: hidden; // necessary for extend classes
}

html  {
	-webkit-text-size-adjust: none; // Prevent font scaling in landscape
}

body {
  margin: 0;
  padding: 0;
  color: $default-text-color;
  background-color: $color-background;
  font-family: $default-font;
  @include font-size($default-font-size);
  @include font-smoothing(antialiased);
}

// Set in $susy (config/_layouts.scss)
@include global-box-sizing;