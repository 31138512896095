//
// PRINT
// ------------------------------------------------
// This file must only contain print styling.
//

//
// DEFAULTS
//

* {
  border-color: black !important;
  background: none !important;
  font-family: "Arial", "Helvetica", sans-serif !important;
  font-size: 12px !important;
  color: black !important;
  text-shadow: none !important;
  -ms-filter: none !important;
      filter: none !important;
}

@page {
  margin: 1cm;
}

h1 {
  font-size: 28pt !important;
}

h2 {
  font-size: 24pt !important;
}

h3 {
  font-size: 18pt !important;
}

h4 {
  font-size: 16pt !important;
}

h2, h3 {
  page-break-after: avoid;
}

h2, h3, p {
  orphans: 3;
  widows: 3;
}

.no-print,
video, audio, object, embed {
  display: none !important;
}

//a[href^=http]:after {
//  content:" <" attr(href) "> ";
//}

a[href="#"] {
  display: none !important;
}

abbr[title]:after {
  content: " (" attr(title) ")";
}

//
// LAYOUT RESET
//

.region-inner-browsehappy,
.region-inner-toolbar,
.region-inner-header,
.region-inner-navigation,
.region-inner-top,
.region-inner-main,
.region-inner-bottom,
.region-inner-footer {
  max-width: none;
  margin: 0;
  padding: 0;
}

.sidebar-first,
.sidebar-second,
.two-sidebars {
  .l-content,
  .l-region--sidebar-first,
  .l-region--sidebar-second {
    width: 100%;
    margin: 0;
    float: none
  }
}

//
// SCREEN CSS OVERRIDES
//

// OVERRIDE YOUR CSS HERE...
