//
// COMMENTS
// ---------------------------------------------------------------------
// This file contains styling for comments.
//

.comments {
  .indented {
    margin-left: 0;
    padding-left: 3rem;
  }
}

.comment {
  border-bottom: $default-border;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.views-row-last .comment {
  border-bottom: 0 none;
  padding-bottom: 0;
}

.view-mode-teaser_comment {
  .field,
  h4, p {
    margin-bottom: 0;
  }
}

.comment-form {
  .form-type-textfield,
  .form-type-item {
    label {
      display: inline-block;
      margin-right: 1rem;
      width: 8rem;
    }
  }
  .form-actions {
    margin-bottom: 1rem;
  }
}

// CK editor

#comment-body-add-more-wrapper {
  .filter-help,
  .filter-guidelines {
    display: none;
  }
}
