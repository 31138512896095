//
// VIEWS
// ---------------------------------------------------------------------
// This file contains styling for views.
//

.view {
  .views-row {
    margin-bottom: 1rem;
  }
}
