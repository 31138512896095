.language-selection-page {
  position: relative;

  .l-page {
    background-color: $color-grey-light;
    height: 100vh;




    h1 {
      text-align: center;
    }
  }
  .l-header {
    .region-inner-header {
      display: block;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 0;

      @include sm {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      @include md {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }
    .l-branding-wrapper {
      display: block;

      .l-branding {
        display: block;
        width: 100%;
        float: none;
        margin-right: 0;
        padding-right: 0;

        .site-info {
          .site-logo {
            background: url('../images/logo.png') no-repeat 0% 50%;
            display: block;
            height: 100px;
            max-width: 400px;
            background-size: 100%;
            margin: auto;

            html.svg & {
              background: url('../images/logo.svg') no-repeat 0% 50%;
              background-size: 100%;
            }

            @include sm() {
              height: 90px;
              background-size: 400px 90px;

              html.svg & {
                background-size: 400px 90px;
              }
            }
          }
        }
      }
    }
  }

  ul {
    @include reset-list;

    li {
      @include reset-list;

      @include md {
        @include span($md-columns/2 of $md-columns);
        &:nth-child(2n) {
          @include last;
        }
      }

      @include lg {
        @include span($lg-columns/3 of $lg-columns);
        &:nth-child(2n) {
          @include span($lg-columns/3 of $lg-columns);
        }

        &:nth-child(3n) {
          @include last;
        }
      }

      a {
        @include font-size(14, 20);
        margin-bottom: 1rem;
        padding: 0.5rem;

        background: $color-dark;
        color: $color-white;
        border-radius: 3px;
        text-align: center;
        text-decoration: none;
        display: block;

        &:hover {
          background: darken($color-primary, 10);
        }

        span.langchoice_label,
        span.language-selection-language {
          display: block;
        }
        span.language-selection-language {
          @include font-size(20);
        }
      }
    }
  }
}
