//
//  LOGIN
//

.block-user {
  ul,
  ul li {
    @include reset-list;
  }

  ul {
    margin-bottom: 1rem;
  }

  ul li {
    margin-right: 1rem;

    float: left;
  }
}
