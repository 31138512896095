//
// OFFCANVAS
// ---------------------------------------------------------------------
// This file contains styling for offcanvas region.
//
.l-region--offcanvas {
  @include md-max{
    background: $color-dark;
    color: $color-white;
    position: absolute;
    width: 16.25rem; // 260px
    right: -16.25rem;
    top: 0;
    bottom: 0;
    z-index: 202;
    box-shadow: inset 16px 0px 12px -16px $color-black;
  }
  @include md {
    display: none;
  }


}
