//
//  SLIDESHOW
//  THIS CSS IS IN COMBINATION WITH RESPONSIVE-SLIDESHOWS.JS
//

.views-slideshow-cycle-main-frame,
.views-slideshow-cycle-main-frame-row {
  width: 100% !important;
}

.views-slideshow-cycle-main-frame-row {
  height: auto !important;
}
