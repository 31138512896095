//
// ADMIN
// ---------------------------------------------------------------------
// This file contains overrides and styling for admin interfaces like
// the admin menu, contextual links, ...
//
// !!! These will only be available in the balance theme,
// not the backend.
//

//
// Admin menu
//

body.admin-menu,
body.adminimal-menu {
  //padding-top: 29px;
  //margin-top: 0 !important;

  #admin-menu .admin-menu-search input {
    height: 28px;
  }
  #admin-menu {
    a {
      font-family: $default-font;
      font-size: 13px;
      padding: 0.5rem 0.5rem;
    }
  }
}

//
// Omega regions demonstration
//

.region--debug:before {
  font-size: 10px;
  right: 5px;
  top: 4px;
  padding: 0px 4px;
  // background-color: $color-primary;
}

//
// Omega browser width indicator
//

body.admin-menu {
  &:after {
    border-left: solid 1px rgba(255,255,255, 0.5);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: $color-grey;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-size: 12px;
  }
  @include xs {
    &:after { content: "xs"; }
  }
  @include sm {
    &:after { content: "sm"; }
  }
  @include md {
    &:after { content: "md"; }
  }
  @include lg {
    &:after { content: "lg"; }
  }
  @include xl {
    &:after { content: "xl"; }
  }
}
