//
// DISPLAY SUITE
// ---------------------------------------------------------------------
// This file contains styling for display suite layout.
//

.ds-center.ds-twocol {
  @include sm {
    .ds-first {
      @include span(floor($sm-columns / 2) of $sm-columns);
    }
    .ds-second {
      @include span(floor($sm-columns / 2) last of $sm-columns);
    }
  }
  @include lg {
    .ds-first {
      @include span(floor($lg-columns / 2) of $lg-columns);
    }
    .ds-second {
      @include span(floor($lg-columns / 2) last of $lg-columns);
    }
  }
}

.ds-center.ds-twocol-auto-fill {
  display: table;
  width: 100%;

  .ds-first, .ds-second {
    display: table-cell;
    vertical-align: top;
  }

  .ds-second {
    width: 100%;
  }
}

body.sidebar-second .ds-center.ds-twocol,
body.sidebar-first .ds-center.ds-twocol {
  @include sm {
    .ds-first {
      @include span(floor(($sm-columns - $sm-sidebar-columns) / 2) of $sm-columns - $sm-sidebar-columns);
    }
    .ds-second {
      @include span(floor(($sm-columns - $sm-sidebar-columns) / 2) last of $sm-columns - $sm-sidebar-columns);
    }
  }
  @include lg {
    .ds-first {
      @include span(floor(($lg-columns - $lg-sidebar-columns) / 2) of $lg-columns - $lg-sidebar-columns);
    }
    .ds-second {
      @include span(floor(($lg-columns - $lg-sidebar-columns) / 2) last of $lg-columns - $lg-sidebar-columns);
    }
  }
}